<template lang="pug">
  include /mixins
  +b.LABEL.d-control-input__label(:for='id')
    +b.g-row.--space_xs.--align_center
      +b.g-cell
        span {{ inputLabel }}
        span(v-if='isRequired') *
        +b.SPAN.ds-caption.--size_xxxs.--color_relief-2(v-if='additionalLabel') &nbsp;({{ additionalLabel }})
      +b.g-cell(v-if="hint")
        +b.hint(v-tooltip.auto="{content: hint, trigger: 'click hover'}")
          +e.icon
            +b.I.icon-hint
</template>

<script>
export default {
  name: 'StaticLabel',

  props: {
    id: {
      type: String,
      required: false,
    },
    inputLabel: {
      type: String,
    },
    additionalLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
    hint: {
      type: String,
    },
  },
}
</script>
