import AdminNavigationLinks from './AdminNavigationLinks'
import Avatar from './Avatar'
import BarChart from './BarChart'
import TextField from './TextField'

export default function install(Vue) {
  Vue.component('admin-navigation-links', AdminNavigationLinks)
  Vue.component('user-avatar', Avatar)
  Vue.component('bar-chart', BarChart)
  Vue.component('text-field', TextField)
}
