import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Create = () => import('./views/Create')
const Display = () => import('./views/Display')
const Orders = () => import('./views/Orders')
const Finances = () => import('./views/Finances')
const SalesPoint = () => import('./views/SalesPoint')
const Provider = () => import('./views/Provider')

const ROUTE_NAME = 'profile:user:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const CREATE = p('create')
const DISPLAY = p('display')
const ORDERS = p('orders')
const FINANCES = p('finances')
const SALES_POINT = p('sales-point')
const PROVIDER = p('provider')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
      },
    }),
    route('create/', Create, CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
      },
    }),
    route(':id/', Provider, PROVIDER, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
      },
      children: [
        route('detail/', Display, DISPLAY, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
          },
        }),
        route('orders/', Orders, ORDERS, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
          },
        }),
        route('finances/', Finances, FINANCES, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.ADMINISTRATOR], options),
          },
        }),
        route('points/', SalesPoint, SALES_POINT, {
          props: true,
          meta: {
            accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
          },
        }),
      ],
    }),
  ]
}
