import { route, prefixRoutes } from '@md/routing'

import { createRoutes as createSettingsRoutes } from './modules/settings/router'
import { createRoutes as createOrdersRoutes } from './modules/orders/router'
import { createRoutes as createFinancesRoutes } from './modules/finances/router'
import { createRoutes as createUsersRoutes } from './modules/users/router'
import { createRoutes as createAnalyticsRoutes } from './modules/analytics/router'
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router'
import { createRoutes as createRequestsRoutes } from './modules/requests/router'
import { createRoutes as createProductModelsRoutes } from './modules/product-models/router'
import { createRoutes as createPayoffRoutes } from './modules/payoff/router'
import { createRoutes as createStocktakingRoutes } from './modules/stocktaking/router'
import { createRoutes as createConsignmentRoutes } from './modules/consignment/router'
import { createRoutes as createDocumentRoutes } from './modules/document/router'
import { createRoutes as createRegistryRoutes } from './modules/registry/router'
import { createRoutes as createReturnsRoutes } from './modules/returns/router'
import { createRoutes as createSenderRoutes } from './modules/sender/router'
import { createRoutes as createWriteoffRoutes } from './modules/writeoff/router'
import { createRoutes as createStorageRoutes } from './modules/storage/router'
import { createRoutes as createMovementRoutes } from './modules/movement/router'

const Profile = () => import('./views/Profile')

export function createRoutes(options) {
  return [
    route('', Profile, null, {
      children: [
        prefixRoutes('settings/', createSettingsRoutes(options)),
        prefixRoutes('order/', createOrdersRoutes(options)),
        prefixRoutes('finances/', createFinancesRoutes(options)),
        prefixRoutes('user/', createUsersRoutes(options)),
        prefixRoutes('analytics/', createAnalyticsRoutes(options)),
        prefixRoutes('transaction/', createTransactionsRoutes(options)),
        prefixRoutes('request/', createRequestsRoutes(options)),
        prefixRoutes('product-model/', createProductModelsRoutes(options)),
        prefixRoutes('payoff/', createPayoffRoutes(options)),
        prefixRoutes('stocktaking/', createStocktakingRoutes(options)),
        prefixRoutes('consignment/', createConsignmentRoutes(options)),
        prefixRoutes('document/', createDocumentRoutes(options)),
        prefixRoutes('registry/', createRegistryRoutes(options)),
        prefixRoutes('returns/', createReturnsRoutes(options)),
        prefixRoutes('sender/', createSenderRoutes(options)),
        prefixRoutes('writeoff/', createWriteoffRoutes(options)),
        prefixRoutes('storage/', createStorageRoutes(options)),
        prefixRoutes('movement/', createMovementRoutes(options)),
        {
          path: '*',
          redirect: { name: 'profile:settings' },
        },
      ],
    }),
  ]
}
