import moment from 'moment'
import { prettifyPhone } from '@utils/transformers'

export const prettifyPrice = p => (p).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
export const normalizeDate = date => (
  /* eslint-disable-next-line */
  date instanceof Date && !isNaN(date) && date || new Date()
)
export const parseDate = date => normalizeDate(new Date(date))

export const install = Vue => {
  Vue.filter('prettifyPhone', prettifyPhone)
  Vue.filter('prettifyPrice', prettifyPrice)
  Vue.filter('normalizeDate', normalizeDate)
  Vue.filter('parseDate', parseDate)
  Vue.filter('formatMomentDate', value => moment(value).format('DD.MM.YYYY'))
  Vue.filter('formatMomentDateTime', value => moment(value).format('DD.MM.YYYY HH:mm'))
}

export default { install }
