import VueI18n from 'vue-i18n'
import VModal from 'vue-js-modal'
import VueBem from '@aspectus/vue-bem'
import VueGrid from '@aspectus/vue-grid'
import Tag from '@aspectus/vue-tag'
import VueDermis from '@aspectus/vue-dermis'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Viewer from 'v-viewer'
import ResourceComponents from '@resource'
import VuePermissions from '@aspectus/vue-permissions'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueSlider from 'vue-slider-component'
import Users from '@md/users'
import Notifications from 'vue-notification'
import { VTooltip } from 'v-tooltip'
import VueTable from '@aspectus/vue-table'
import { ObserveVisibility } from 'vue-observe-visibility'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import { DESKTOP_MEDIA } from '@utils/constances'
import draggable from 'vuedraggable'
import VueSelectionController from '@aspectus/vue-selection-controller'
import VueAllSelectionController from '@aspectus/vue-all-selection-controller'
import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'

const VueInputMask = require('vue-inputmask').default

const IS_DESKTOP = window.innerWidth >= DESKTOP_MEDIA

export function install(Vue) {
  Vue.use(VueRouter)
  Vue.use(Vuex)
  Vue.use(VueI18n)
  Vue.use(VueGrid)
  Vue.use(Tag)
  Vue.use(VModal, {
    dynamic: true,
    dialog: true,
    dynamicDefaults: {
      clickToClose: IS_DESKTOP,
      height: 'auto',
      transition: IS_DESKTOP ? 'nice-modal-fade' : false,
      scrollable: true,
    },
  })
  Vue.use(VueBem, {
    blockNameKey: 'block',
  })
  Vue.use(VueInputMask)
  Vue.use(ResourceComponents)
  Vue.use(VueDermis)
  Vue.use(Viewer, {
    defaultOptions: {
      title: false,
      toolbar: {
        play: false,
        zoomIn: true,
        zoomOut: true,
        oneToOne: true,
        reset: true,
        prev: true,
        next: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: true,
        flipVertical: true,
      },
    },
  })
  Vue.use(VuePermissions)
  Vue.use(VueAwesomeSwiper)
  Vue.use(Users)
  Vue.use(VueTable)
  Vue.use(VueReCaptcha, {
    siteKey: window.recaptchaKey,
  })
  Vue.use(VueSelectionController)
  Vue.use(VueAllSelectionController)
  Vue.use(ElementUI)
  Vue.use(ElementTiptapPlugin)
  Vue.use(Notifications)
  Vue.component('range-slider', VueSlider)
  Vue.component('draggable', draggable)
  Vue.directive('tooltip', VTooltip)
  Vue.directive('observe-visibility', ObserveVisibility)
}

export default { install }
