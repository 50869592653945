import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const ListController = () => import('./views/ListController')
const DisplayController = () => import('./views/DisplayController')

const ROUTE_NAME = 'profile:order:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const DISPLAY = p('display')

export function createRoutes(options) {
  return [
    route('list/', ListController, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.CUSTOMER, TYPES.SHIPPER, TYPES.ADMINISTRATOR, TYPES.MANAGER, TYPES.STOREKEEPER], options),
      },
    }),
    route(':id/detail/', DisplayController, DISPLAY, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.SHIPPER, TYPES.ADMINISTRATOR, TYPES.MANAGER, TYPES.STOREKEEPER], options),
      },
    }),
  ]
}
