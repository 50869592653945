import { baseResource } from '@resource/resource'
import { friendlyUrlGenerator } from '@utils/friendly'
import { prefixLanguage } from '@utils/urls'

const SALES_LIST_URL_PREFIX = prefixLanguage('/sales')
const SALES_LIST_URL_POSTFIX = 'ajax/filters'

const OFFER_LIST_URL_PREFIX = prefixLanguage('/super-offers')
const OFFER_LIST_URL_POSTFIX = 'ajax/filters'

const PRODUCT_LIST_URL_PREFIX = prefixLanguage('/catalog')
const PRODUCT_LIST_URL_POSTFIX = 'ajax/filters'

const orderAdditional = {
  path: [
    'prefix',
    'label',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

export const productsListResource = friendlyUrlGenerator([
  PRODUCT_LIST_URL_PREFIX,
  PRODUCT_LIST_URL_POSTFIX,
], baseResource, orderAdditional)

export const salesListResource = friendlyUrlGenerator([
  SALES_LIST_URL_PREFIX,
  SALES_LIST_URL_POSTFIX,
], baseResource, orderAdditional)

export const offersListResource = friendlyUrlGenerator([
  OFFER_LIST_URL_PREFIX,
  OFFER_LIST_URL_POSTFIX,
], baseResource, orderAdditional)
