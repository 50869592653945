import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')
const Display = () => import('./views/Display')
const SurplusesShortages = () => import('./views/SurplusesShortages')

const ROUTE_NAME = 'profile:stocktaking:'

const p = name => ROUTE_NAME + name

const LIST = p('list')
const CREATE = p('create')
const DISPLAY = p('display')
const SURPLUSES_SHORTAGES = p('surpluses-shortages')

export function createRoutes(options) {
  return [
    route('list/', List, LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER, TYPES.STOREKEEPER], options),
      },
    }),
    route('create/', Display, CREATE, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER, TYPES.STOREKEEPER], options),
      },
    }),
    route(':id/detail/', Display, DISPLAY, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER, TYPES.STOREKEEPER], options),
      },
    }),
    route(':id/surpluses-shortages/', SurplusesShortages, SURPLUSES_SHORTAGES, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER, TYPES.STOREKEEPER], options),
      },
    }),
  ]
}
