import { accessGuard } from '@md/permissions'
import { prefixRoutes } from '@md/routing'
import { prefixLanguage } from '@utils/urls'
import { createRoutes as createProfileRoutes } from '@components/Profile/router'

export function createRoutes(options) {
  return [
    prefixRoutes(prefixLanguage('/cabinet/'), createProfileRoutes(options)),
  ]
}

// export function scrollBehavior({ savedPosition }) {
//   if (savedPosition) {
//     return savedPosition
//   }

//   return {
//     x: window.pageXOffset,
//     y: window.pageYOffset,
//   }
// }

export function scrollBehavior() {
  return new Promise(resolve => {
    setTimeout(() => {
      const app = document.getElementById('app')

      if (!app) return

      resolve(app.scrollIntoView({ behavior: 'smooth' }))
    }, 200)
  })
}

export function createRouter(options = {}) {
  const { Router, Vue } = options
  const router = new Router({
    mode: 'history',
    base: '/',
    scrollBehavior,
    routes: createRoutes({ Router, Vue }),
  })

  router.beforeEach(accessGuard(options))
  router.afterEach(() => {
    const htmlEl = document.querySelector('html')

    htmlEl.classList.remove('is-overflow')
  })

  return router
}
