<template lang='pug'>
  include /mixins
  div
    +b.i-catalog
      +e.sidebar
        catalog-sidebar(
          :filters="filters"
          :filtersMap="preparedFilters"
          @update:filters="changeFilters"
          :pagination="pagination",
          :attributesResource='attributesResource',
          :additionalResource="additionalResource"
          :label='label'
          :totalItems="totalItems"
          :filterName="filterName"
        )
      +e.content
        +b.i-preloader.--variant_1(:class="{'is-active': loading}")
          +e.item.--size_md
        +b.ds-panel.--space_xl.--space_2xl-xl
          +e.element.--offset_bottom
            +b.i-panel
              +b.g-row.--appearance_spaced.--justify_between.--align_center
                +b.g-cell.g-cols.--12-xs.--6-sm.--narrow-xl
                  +b.P.ds-caption.--size_xs.--size_sm-md {{ _("Найдено:") }}
                    |&nbsp;
                    +b.SPAN.ds-caption.--size_xs.--size_sm-md.--bold {{ pluralizedProductWord }}
                +b.g-cell.g-cols.--12-xs.--6-sm.--4-md
                  sorting-widget(
                    :value="filters.filters ? filters.filters.order : 'default'",
                    :filters="filters"
                    :choices="orderChoices.items"
                    @input="changeFilters"
                  )
        renderer(
          v-if="result"
          :result="result.data"
        )

        slot(
          v-else
          name="content"
        )

    +b.ds-panel.--space_md.--space_8xl-xl(
      v-if="pagination.total > pagination.limit"
    )
      +e.element.--offset_top
        ui-limit-pagination(
          :pagination='pagination'
          @input='handlePaginate'
        )
</template>

<script>
import SortingWidget from '@components/Filters/Sorting'
import { simplePluralize } from '@utils/strings/pluralize'
import CatalogSidebar from './Sidebar'
import Renderer from './Renderer'

export default {
  components: {
    CatalogSidebar,
    SortingWidget,
    Renderer,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'filtersMap',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
  ],

  computed: {
    pluralizedProductWord() {
      const variants = [
        this._('товар'),
        this._('товара'),
        this._('товаров'),
      ]
      return simplePluralize(this.totalItems, variants)
    },

    orderChoices() {
      return this.filtersMap.find(el => 'order' === el.name)
    },

    preparedFilters() {
      return this.filtersMap.filter(el => 'order' !== el.name)
    },
  },

  methods: {
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
