import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const Display = () => import('./views/Display')

const ROUTE_NAME = 'profile:finances'

export function createRoutes(options) {
  return [
    route('', Display, ROUTE_NAME, {
      meta: {
        accessConfig: accessPermission([TYPES.SHIPPER], options),
      },
    }),
  ]
}
