import { snakelize } from '@utils/case'

// import CheckboxWidget from '../Widgets/CheckboxWidget'
// import CheckboxWidgetSingle from '../Widgets/CheckboxWidgetSingle'
import RangeWidget from '../Widgets/RangeWidget'
import RadioWidget from '../Widgets/RadioWidget'
import SelectWidget from '../Widgets/SelectWidget'
import MultiselectWidget from '../Widgets/MultiselectWidget'
import AutocompleteMultiselectWidget from '../Widgets/AutocompleteMultiselectWidget'
import BooleanWidget from '../Widgets/BooleanWidget'
import ColorWidget from '../Widgets/ColorWidget'
import SearchWidget from '../Widgets/SearchWidget'
import DateRangeWidget from '../Widgets/DateRangeWidget'
import TreeSelectWidget from '../Widgets/TreeSelectWidget'
import CategoriesWidget from '../Widgets/CategoriesWidget'

const WIDGETS_MAP = {
  // checkbox: CheckboxWidget,
  // 'checkbox-single': CheckboxWidgetSingle,
  range: RangeWidget,
  radio: RadioWidget,
  color: ColorWidget,
  select: SelectWidget,
  datefield: DateRangeWidget,
  BooleanWidget,
  input: SearchWidget,
  checkbox: MultiselectWidget,
  autocomplete: AutocompleteMultiselectWidget,
  'multi-select': MultiselectWidget,
  'tree-select': TreeSelectWidget,
  subcategories: CategoriesWidget,
}

export default {
  widgetsMap: WIDGETS_MAP,

  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
      }
    },
  },

  methods: {
    onlyValue(key, value) {
      this.$emit('input', { [key]: value })
    },

    updateAutocompleteValue(filter, value) {
      const { autocompleteSettings: { valuePath }, name } = filter

      this.$emit('input', { ...this.value, [name]: value[valuePath] })
    },

    updateValue(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },

    updateMultipleValues(key, values) {
      const res = {}
      const [min, max] = values
      const snakelizedKey = snakelize(key)
      const fromKey = `${snakelizedKey}_from`
      const toKey = `${snakelizedKey}_to`

      if (min) {
        res[fromKey] = min
      } else {
        delete this.value[fromKey]
      }

      if (max) {
        res[toKey] = max
      } else {
        delete this.value[toKey]
      }

      this.$emit('input', { ...this.value, ...res })
    },
  },
}
