export const TYPES = {
  CUSTOMER: 'A1',
  SHIPPER: 'A2',
  MANAGER: 'A3',
  ADMINISTRATOR: 'A4',
  STOREKEEPER: 'A5',
}

export const USER_TYPES = {
  [TYPES.CUSTOMER]: { isCustomer: true },
  [TYPES.SHIPPER]: { isShipper: true },
  [TYPES.MANAGER]: { isManager: true },
  [TYPES.ADMINISTRATOR]: { isAdministrator: true },
  [TYPES.STOREKEEPER]: { isStoreKeeper: true },
}

export const REDIRECT_MAP = {
  [TYPES.CUSTOMER]: 'profile:order:list',
  [TYPES.SHIPPER]: 'profile:order:list',
  [TYPES.MANAGER]: 'profile:order:list',
  [TYPES.ADMINISTRATOR]: 'profile:order:list',
  [TYPES.STOREKEEPER]: 'profile:order:list',
}
