<template>
  <resource-loader-controller
    :resource="resource"
    v-slot="{ receive, loading, result }"
  >
    <delayed-executor-controller
      :delay="300"
      :receiver="receive"
      v-slot="{ receive: delayedReceive }"
    >
      <view-component
        ref="view"
        v-bind="$attrs"
        @input="submit"
        @select="select"
        :value="value"
        :filter="filter"
        :result="result"
        :loading="loading"
        :receive="delayedReceive"
        :rules="rules"
        :disabled="disabled"
        :params="params"
        :max-height="maxHeight"
      />
    </delayed-executor-controller>
  </resource-loader-controller>
</template>

<script>
import { createResource } from '@resource/resource'

export default {
  name: 'filter-autocomplete-widget-controller',

  components: {
    'view-component': () => import('./Component'),
  },

  props: {
    value: {},
    filter: {},
    rules: {},
    disabled: {},
    params: {},
    lookup: {},
    maxHeight: {},
  },

  computed: {
    resource() {
      const { url } = this.filter
      const getParams = ['query', 'page', 'limit']

      if (this.lookup) {
        getParams.push(this.lookup)
      }

      const queryString = getParams.join(',')

      return createResource(`${url}{?${queryString}}`)
    },
  },

  methods: {
    submit(val) {
      this.$emit('input', val)
    },

    select(val) {
      this.$emit('select', val)
    },

    updateList() {
      this.$refs.view.receiveWithParams()
    },
  },
}

</script>
