<template lang="pug" functional>
  include /mixins
  ds-table(tag="table", v-bind="data.attrs", :class="[data.staticClass, data.class]")
    slot(name="head", :fields="props.fields")
      thead(class="ds-table__head", v-if="props.displayHeader")
        ds-table-row(:relief="true")
          ds-table-cell(
            tag="th", :head="true",
            v-for="field in props.fields",
            :key="field.key",
            :data-title="field.label",
            :appearance="[`field-${field.key}`, `head-${field.key}`]"
            :class="{'ds-table__cell--interactive': props.sortFields.includes(field.key), 'is-active': props.activeSort.hasOwnProperty(field.key)}"
            @click.prevent="props.sortFields.includes(field.key) ? props.changeSort(field.key) : null"
          )
            slot(:name="`head-cell-${field.key}`", :fields="props.fields", :field="field")
              slot(name="head-cell", :fields="props.fields", :field="field")
                +b.g-row.--justify_between.--align_center.--appearance_nowrap
                  +b.g-cell
                    span {{ field.label }}
                  template(v-if="props.sortFields.includes(field.key)")
                    +b.g-cell
                      +b.g-row.--space_sm.--align_center.--appearance_nowrap
                        +b.g-cell(v-if="props.activeSort.hasOwnProperty(field.key)")
                          +b.ds-inliner.--size_xxs.--color_main(@click.stop="props.resetSort(field.key)")
                            +e.body
                              +b.I.icon-close
                        +b.g-cell
                          +b.g-row.--appearance_column
                            +b.g-cell
                              +b.ds-inliner.--size_sm(:class="{'ds-inliner--color_main': props.activeSort[field.key] === true}")
                                +e.body.is-rotate
                                  +b.I.icon-dropdown
                            +b.g-cell
                              +b.ds-inliner.--size_sm(:class="{'ds-inliner--color_main': props.activeSort[field.key] === false}")
                                +e.body
                                  +b.I.icon-dropdown

          ds-table-cell(tag="th", :head="true", v-if="scopedSlots.action", appearance="action-head")
            slot(name="action-head")

    slot(
      name="item",
      v-for="(item, i) in props.items",
      :fields="props.fields", :item="item", :index="i"
    )
      vue-table-item(:key="i", :fields="props.fields", :item="item", :index="i")
        template(v-for="(slot, key) in scopedSlots", v-slot:[key]="scope")
          slot(:name="key", v-bind="scope")

    slot(
      name="after-body",
      :item="props.aggregate"
    )
      vue-table-item(
        :fields="props.aggregateFields",
        :item="props.aggregate",
        :index="1",
        v-if="props.aggregate"
      )
        template(v-for="(slot, key) in scopedSlots", v-slot:[key]="scope")
          slot(:name="key", v-bind="scope")

    slot(name="foot", :fields="props.fields")
      tfoot(class="ds-table__foot", v-if="props.displayFooter")
        ds-table-row(:relief="true")
          ds-table-cell(
            tag="th", :head="true",
            v-for="field in props.fields"
            :key="field.key"
            :data-title="field.label"
            :appearance="[`field-${field.key}`, `head-${field.key}`]"
          )
            slot(:name="`foot-cell-${field.key}`", :fields="props.fields", :field="field")
              slot(name="foot-cell", :fields="props.fields", :field="field")
                | {{ field.label }}

          ds-table-cell(tag="th", :head="true", v-if="scopedSlots.action", appearance="action-head")
            slot(name="action-head")
</template>

<script>

export default {
  name: 'vue-table',
  props: {
    fields: Array,
    aggregateFields: Array,
    items: Array,
    aggregate: {},
    displayHeader: Boolean,
    displayFooter: Boolean,
    sortFields: {
      default() {
        return []
      },
    },
    activeSort: {
      default() {
        return {}
      },
    },
    changeSort: {},
    resetSort: {},
  },
};

</script>
