const Login = () => import('./Login')
const PasswordRestore = () => import('./PasswordRestore')
const Registration = () => import('./Registration')
const PasswordSet = () => import('./PasswordSet')
const EmailSet = () => import('./EmailSet')
const EmailVerificationByCode = () => import('./EmailVerificationByCode')
const EmailVerificationComplete = () => import('./EmailVerificationComplete')

export default function install(Vue) {
  Vue.component('auth-login', Login)
  Vue.component('auth-password-restore', PasswordRestore)
  Vue.component('auth-registration', Registration)
  Vue.component('auth-password-set', PasswordSet)
  Vue.component('auth-email-set', EmailSet)
  Vue.component('auth-email-verification-by-code', EmailVerificationByCode)
  Vue.component('auth-email-verification-complete', EmailVerificationComplete)
}
