<template lang="pug">
include /mixins.pug
+b.d-control-input.--variant_4
  +e.SELECT.element(
    name="sorting",
    v-model="checked",
    @change="submit"
  )
    option(
      selected
      value=""
    ) {{ _("По умолчанию") }}
    template(v-for="choice in choices")
      option(:value="choice.slug") {{ choice.label }}
  +e.icon.--append.--events-none
    +b.I.icon-dropdown
</template>

<script>
export default {
  name: 'Sorting',

  props: {
    value: {},
    filters: {},
    choices: {},
  },

  data() {
    return {
      checked: this.value,
    }
  },

  watch: {
    value(nval) {
      this.checked = this.normalize(nval)
    },
  },

  mounted() {
    this.checked = this.normalize(this.value)
  },

  methods: {
    submit() {
      this.$nextTick(() => {
        this.filters.filters.order = this.checked ? [this.checked] : ''
        this.$emit('input', this.filters)
      })
    },

    normalize(value) {
      if (!value) return ''
      const [val] = value
      const checked = this.choices.find(el => {
        if (el.slug === val) {
          return el
        }
        return false
      })
      return checked ? checked.slug : ''
    },
  },
}
</script>
