<template lang="pug">
  include /mixins
  ui-switch(
    :label-right="filter.label"
    v-model="checked"
    @input="updateValue"
  )
</template>
<script>
export default {
  name: 'BooleanWidget',

  props: ['value', 'filter'],

  data() {
    return {
      checked: null,
    }
  },

  watch: {
    value: {
      immediate: true,
      handler(nval) {
        if ('undefined' === typeof nval) {
          this.setInitialValue(nval)
        } else {
          this.checked = nval
        }
      },
    },
  },

  mounted() {
    this.setInitialValue()
  },

  methods: {
    setInitialValue() {
      const { initial } = this.filter
      if (initial) {
        this.checked = initial
        this.updateValue()
      }
    },

    updateValue() {
      this.$emit('input', this.checked)
    },
  },
}
</script>
