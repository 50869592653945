import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'

import ErrorComponent from './ErrorComponent'
import MultiSelect from './Multiselect'
import Datepicker from './DatePicker'
import ControlCheckbox from './Checkbox'
import StaticLabel from './StaticLabel'
import UnifiedControlElement from './UnifiedControlDescriptor'
import PrependComponent from './PrependComponent'
import AppendComponent from './AppendComponent'

const composerForInput = (input, label = StaticLabel, base = UnifiedControlElement) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(base)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  bails: true,
  controllerComponent: () => NoneController,
  prependComponent: () => PrependComponent,
  appendComponent: () => AppendComponent,
})(Descriptor)

const InputWithStaticLabel = composerForInput('Input')
const ControlStaticInput = composer(InputWithStaticLabel)

const MultiselectStaticLabel = composerForInput('MultiSelect')
const ControlStaticMultiselect = composer(MultiselectStaticLabel)

const TextareaStaticLabel = composerForInput('Textarea')
const ControlStaticTextarea = composer(TextareaStaticLabel)

const DatePickerStaticLabel = composerForInput('Datepicker')
const ControlStaticDatePicker = composer(DatePickerStaticLabel)

export default function install(Vue, { prefix = 'DControl' } = {}) {
  Vue.component('MultiSelect', MultiSelect)
  Vue.component('Datepicker', Datepicker)
  Vue.component('ErrorComponent', ErrorComponent)
  Vue.component('PrependComponent', PrependComponent)
  Vue.component('AppendComponent', AppendComponent)
  Vue.component(`${prefix}StaticInput`, ControlStaticInput)
  Vue.component(`${prefix}StaticMultiselect`, ControlStaticMultiselect)
  Vue.component(`${prefix}StaticTextarea`, ControlStaticTextarea)
  Vue.component(`${prefix}StaticDatepicker`, ControlStaticDatePicker)
  Vue.component(`${prefix}Checkbox`, ControlCheckbox)
}
