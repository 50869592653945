import {
  headersMiddleware,
  overrideMiddleware,
} from '@aspectus/resource'

import { getCookie, setCookie } from '@utils/cookies'

export const setAuthToken = token => {
  setCookie('AUTH_TOKEN_IS_ACTIVE', token, ';max-age=3000')
  setCookie('AUTH_TOKEN', token, ';max-age=3500')
}

export const HEADERS_MIDDLEWERE = headersMiddleware(overrideMiddleware({
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRFToken': getCookie('csrftoken'),
  'Accept-Language': window.language,
}))
