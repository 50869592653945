/* eslint-disable */
import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import {
  required, confirmed, min, min_value as minValue,
  max, max_value as maxValue,
} from 'vee-validate/dist/rules'

for (const rule in rules) {
  extend(rule, rules[rule])
}

export const createCustomRules = app => {
  extend('numeric', { ...required, message: app._('Поле должно быть числом.') });
  extend('required', { ...required, message: app._('Поле обязательно к заполнению.') });
  extend('confirmed', { ...confirmed, message: app._('Пароли должны совпадать.') });
  extend('min', { ...min, message: (e, a) => `${app._('Количество символов должно быть не меньше')} ${a.length}` })
  extend('min_value', { ...minValue, message: app._('Минимальное значение {min}.') });
  extend('max', { ...max, message: (e, a) => `${app._('Количество символов не должно превышать')} ${a.length}` })
  extend('max_value', { ...maxValue, message: app._('Максимальное значение {max}.') });
  
  extend('emailMethod', {
    validate(value) {
      return /^([a-zA-Z0-9_.+-]{2,64})+@([a-zA-Z0-9-]{1,64})+\.([a-zA-Z0-9-.]{2,64})+$/.test(value)
    },
    message() {
      return app._('Поле должно быть действительным электронным адресом.')
    },
  })
  
  extend('phone', {
    validate(value) {
      return /^\+38 \(0[+() -\d]+$/.test(value) || /^\+380[+() -\d]+$/.test(value)
    },
    message: app._('Телефон недействителен'),
  })
  
  extend('cardMethod', {
    validate(value) {
      return /^\d{16}$/g.test(value)
    },
    message: app._('Номер карты недействителен'),
  })
  
  extend('iban', {
    validate(value) {
      const regexpValid = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/g.test(value)
      const lengthValid = value.length <= 29

      return regexpValid && lengthValid
    },
    message: app._('IBAN не валидный'),
  })

  extend('decimalMethod', {
    validate(value) {
      return /^[0-9.]*$/i.test(value)
    },
    message() {
      return app._('Поле должно быть числом')
    },
  })

  extend('decimalMethodNegative', {
    validate(value) {
      return /^[0-9.-]*$/i.test(value)
    },
    message() {
      return app._('Поле должно быть числом')
    },
  })

  extend('digitMethod', {
    validate(value) {
      return /^[0-9]*$/i.test(value)
    },
    message() {
      return app._('Поле должно быть числом')
    },
  })

  extend('url', {
    validate(str) {
      const pattern = new RegExp('^(https?:\\/\\/)?'+
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
        '((\\d{1,3}\\.){3}\\d{1,3}))'+
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
        '(\\?[;&a-z\\d%_.~+=-]*)?'+
        '(\\#[-a-z\\d_]*)?$','i')
  
      return !!pattern.test(str)
    },
    message() {
      return app._('Введите корректный URL')
    },
  })

  extend('arrayLengthMin', {
    validate(value, args) {
      const isNotDeletedItems = value.reduce((acc, el) => {
        if (el && !el.Delete) acc++
        return acc
      }, 0)
      return isNotDeletedItems >= args[0]
    },
    message(value, sec) {
      return `${app._('Минимальное количество -')} ${sec[0]}`
    },
  })

  extend('username', {
    validate(value) {
      return /^[a-zA-Zа-яА-ЯяёЁЇїІіЄєҐґ `'’ʼ-]+$/i.test(value)
    },
    message() {
      return app._('Поле имеет ошибочный формат.')
    },
  })

  extend('code', {
    validate(value, args) {
      return value.length === args[0]
    },
    message() {
      return `${app._('Неверная длина кода')}`
    },
  })
}
