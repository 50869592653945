import Multiselect from 'vue-multiselect'
import { createHOCc } from 'vue-hoc'

export const multiselectDefaults = createHOCc({
  props: {
    placeholder: {
      default: '',
    },
    tagPlaceholder: {
      default: '',
    },
    showLabels: {
      default: false,
    },
    searchable: {
      default: false,
    },
    multiple: {
      default: false,
    },
    closeOnSelect: {
      default: true,
    },
    hideSelected: {
      default: false,
    },
    taggable: {
      default: false,
    },
    optionsLimit: {
      default: 2000,
    },
    loading: {
      default: false,
    },
    internalSearch: {
      default: true,
    },
    clearOnSelect: {
      default: false,
    },
    openDirection: {
      default: 'bottom',
    },
  },
}, {
  props: {
    openDirection() {
      return this.openDirection || 'bottom'
    },
    tagPlaceholder() {
      return this.tagPlaceholder
    },
    showLabels() {
      return this.showLabels
    },
    searchable() {
      return this.searchable
    },
    multiple() {
      return this.multiple
    },
    internalSearch() {
      return this.internalSearch
    },
    closeOnSelect() {
      return this.closeOnSelect
    },
    hideSelected() {
      return this.hideSelected
    },
    taggable() {
      return this.taggable
    },
    placeholder() {
      return this.placeholder
    },
    optionsLimit() {
      return this.optionsLimit
    },
    loading() {
      return this.loading
    },
    clearOnSelect() {
      return this.clearOnSelect
    },
  },
})

export default multiselectDefaults(Multiselect)
