/* eslint-disable */
import { path, pipe, partial } from 'ramda';

export class TableField {
  constructor(key, label, resolver) {
    this.key = key;
    this.label = label;
    this.resolver = resolver;
  }

  getValue(context, ...args) {
    return this.resolver.apply(context, args);
  }
}

const ar = key => (Array.isArray(key) ? key : [key]);
const keyNormalizer = func => (key, ...attrs) => func(ar(key), ...attrs);

function transformDatetime(config, value) {
  if (value) {
    return this.$d(value ? new Date(value) : '-', config);
  }
  return this.$t('-');
}

function transformNumber(config, value) {
  return this.$n(value, config);
}
export const emptyTableField = new TableField('', '', () => '');

export const tableField = keyNormalizer((key, label) => (
  new TableField(key.join('_'), label, path(key))
));

export const tableFieldDateResolver = (key, config = 'datetime') => pipe(
  key, partial(transformDatetime, [config])
);
export const tableFieldDate = keyNormalizer((key, label, config) => (
  new TableField(
    key.join('_'), label, tableFieldDateResolver(path(key), config)
  )
));

export const tableFieldNumberResolver = (key, config = null) => pipe(
  key, partial(transformNumber, [config])
);
export const tableFieldNumber = keyNormalizer((key, label, config = null) => (
  new TableField(
    key.join('_'), label, pipe(path(key), partial(transformNumber, [config]))
  )
));
export const tableFieldNumberWithResolver = keyNormalizer((key, label, config = null) => (
  new TableField(
    key.join('_'), label, tableFieldNumberResolver(pipe(path(key), config))
  )
));
