import {
  cartRetrieveResource,
} from '@services/cart.service'

export function createStore() {
  return {
    namespaced: true,

    state: {
      cart: {
        groups: [],
      },
    },

    actions: {
      async getCartState({ commit }) {
        const { data } = await cartRetrieveResource.execute()

        commit('CHANGE_CART_STATE', data)
      },
    },

    mutations: {
      CHANGE_CART_STATE(state, result) {
        state.cart = result
      },
    },
  }
}
