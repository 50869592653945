<template lang="pug">
  include /mixins.pug
  +b.d-control-input
    +e.label {{ label }}
    +e.wrapper
      +e.SPAN.element(
        :class="[contentClass, { 'd-control-input__element--align_center d-control-input__element--space_1 d-control-input__element--height_auto': !isTextarea }]"
      ) {{ content }}
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    content: {
      type: [String, Number],
    },
    contentClass: {
      type: String,
    },
    isTextarea: {
      type: Boolean,
    },
  },
}
</script>
