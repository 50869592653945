import { mapState } from 'vuex'
import { renderSlim } from '@aspectus/vue-utils'
import { Tag } from '@aspectus/vue-tag'

export default {
  name: 'user-access-state',

  props: {
    permissions: Array,
  },

  data() {
    return {
      hasAccess: false,
    }
  },

  computed: {
    ...mapState('users/auth', [
      'user',
    ]),
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(nval) {
        const { info } = nval
        if (info) {
          this.checkAccess(info.role)
        }
      },
    },
  },

  methods: {
    checkAccess(role) {
      if (this.permissions.includes(role)) {
        this.hasAccess = true
      } else {
        this.hasAccess = false
      }
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      hasAccess: this.hasAccess,
    }) || [], h, Tag)
  },
}
