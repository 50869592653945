<template lang="pug">
include /mixins
+b.g-row.--appearance_spaced
  +b.g-cell.g-cols.--12-xs(v-for='filter in preparedFilters')
    +b.P.ds-caption.--size_xs {{ filter.title }}
    +b.ds-panel.--space_xs
      +e.element.--offset_top
        tag(
          :class="{'control-descriptor--select-size_sm': filter.type === 'checkbox'}"
          :tag="widgetsMap[filter.type]"
          :value="value[filter.name]"
          :filter-name="filter.name"
          :need-display-placeholder="false"
          :filter="filter"
          @input="'range' === filter.type ? updateMultipleValues(filter.name, $event) : updateValue(filter.name, $event)"
        )
</template>

<script>
export default {
  props: {
    preparedFilters: {
      type: Array,
    },
    updateMultipleValues: {
      type: Function,
    },
    updateValue: {
      type: Function,
    },
    widgetsMap: {
      type: Object,
    },
    value: {},
  },
}
</script>
