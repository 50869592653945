<template lang='pug'>
  include /mixins
  +b.relative
    +b.i-preloader.--variant_1(:class="{'is-active': loading}")
      +e.item.--size_md

    renderer(
      v-if="result"
      :result="result.data"
    )

    slot(
      v-else
      name="content"
    )

    +b.ds-panel.--space_md.--space_8xl-xl(
      v-if="pagination.total > pagination.limit"
    )
      +e.element.--offset_top
        ui-limit-pagination(
          :pagination='pagination'
          @input='handlePaginate'
        )
</template>

<script>
import SortingWidget from '@components/Filters/Sorting'
import CatalogSidebar from './Sidebar'
import Renderer from './Renderer'

export default {
  components: {
    CatalogSidebar,
    SortingWidget,
    Renderer,
  },

  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'totalItems',
  ],

  methods: {
    handlePaginate({ limit: limitValue, offset }) {
      if (this.loading) {
        return
      }
      const limit = limitValue || this.pagination.limit
      this.changePagination({ limit, offset })
    },
  },
}

</script>
