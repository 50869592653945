<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,

  props: {
    chartData: {
      type: Object,
      default: null,
    },
    chartOptions: {
      type: Object,
      default: () => ({
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: '#52565F',
              fontSize: 12,
            },
          }],
          yAxes: [{
            gridLines: {
              display: true,
              color: '#F3F3F3',
              lineWidth: 2,
              zeroLineColor: '#F3F3F3',
              zeroLineWidth: 2,
            },
            ticks: {
              fontColor: '#52565F',
              fontSize: 12,
            },
          }],
        },
      }),
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.chartOptions)
  },
}
</script>
