import {
  createResource,
  patchResource,
  postResource,
} from '@resource/resource'
import { prefixAPI } from '@resource/api'

const MODEL_PREFIX = 'cabinet'

export const PROFILE_BALANCE = prefixAPI('/balance/', MODEL_PREFIX)
export const PROFILE_RETRIEVE = prefixAPI('/personal-data/', MODEL_PREFIX)
export const PROFILE_UPDATE = prefixAPI('/personal-data/', MODEL_PREFIX)

export const CABINET_FILTER = prefixAPI('/get-attributes/{preset_key}/', MODEL_PREFIX)
export const CABINET_COUNTERS = prefixAPI('/instant-information/', MODEL_PREFIX)
export const CABINET_EXPORT_PROM_UA = prefixAPI('/catalog-export-prom-ua/{?export}', MODEL_PREFIX)
export const CABINET_REQUISITES_LIST = prefixAPI('/requisites/list/', MODEL_PREFIX)
export const CABINET_BARCODE_SCAN = prefixAPI('/barcode/scan/', MODEL_PREFIX)

export const profileBalanceResource = createResource(PROFILE_BALANCE)
export const profileRetrieveResource = createResource(PROFILE_RETRIEVE)
export const profileUpdateResource = createResource(PROFILE_UPDATE, patchResource)

export const cabinetFilterResource = createResource(CABINET_FILTER)
export const cabinetCountersResource = createResource(CABINET_COUNTERS)
export const cabinetRequisitesListResource = createResource(CABINET_REQUISITES_LIST)
export const cabinetBarcodeScanResource = createResource(CABINET_BARCODE_SCAN, postResource)
