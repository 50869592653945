<template lang="pug">
  include /mixins
  +b.relative
    ui-cropper(@image:crop="cropSuccess")
      template(slot="button")
        +b.i-circle.--size_sm.--fs_md.--position_absolute.--variant_main.--interactive.--uploader
          +b.I.icon-photo
        +b.i-avatar(v-if="avatar")
          +e.IMG.image(:src="avatar")
        +b.i-avatar(v-else)
          +e.icon
            +b.I.icon-user
</template>

<script>
export default {
  props: {
    avatar: {},
  },

  methods: {
    cropSuccess(data) {
      this.$emit('avatar:update', data)
    },
  },
}
</script>
