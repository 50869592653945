<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :get-data="getData"
    postfix=""
    :need-add-search-params="false"
    :order="queryParamsOrder"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="listResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      v-slot="paginatedProps"
      @result="$emit('result', $event)"
    )
      component(
        ref="controller"
        :is="controllerName"
        :attributesResource='filtersResource'
        :additionalResource='additionalResource'
        :filterKey="filterKey"
        v-bind="paginatedProps",
        :totalItems="paginatedProps.pagination ? paginatedProps.pagination.total : 0"
        :label='label',
        :filterName="filterName"
        :needSearch="needSearch"
        :needAddBtns="needAddBtns"
        :initial="initial"
      )
        template(#heading)
          slot(name="heading")
        template(#content)
          slot(
            name="content"
            :items="paginatedProps.result ? paginatedProps.result.items : []"
            :total="(paginatedProps.result && paginatedProps.result.pagination) ? paginatedProps.result.pagination.total : 0"
          )
</template>

<script>
import { isEmpty } from 'lodash'
import ControllerCabinet from '@components/List/ControllerCabinet'
import { queryStringParser, parsePaginationUrl } from '@components/Filters/transformers'

export default {
  components: {
    ControllerCabinet,
  },

  props: {
    initial: {},
    baseUrl: {},
    listResource: {},
    filtersResource: {},
    additionalResource: {},
    label: {},
    needSearch: {},
    controllerName: {},
    filterName: {},
    needAddBtns: {},
    filterKey: {},
  },

  data() {
    return {
      queryParamsOrder: {
        path: [
          'prefix',
        ],
        query: [
          'query',
        ],
      },
    }
  },

  // created() {
  //   const query = parsePaginationUrl()
  //   this.initialParams = {
  //     ...this.initial,
  //     ...query,
  //   }
  // },

  methods: {
    getData(orderParams) {
      if (this.$refs.controller && this.$refs.controller.getData) {
        this.$refs.controller.getData(orderParams)
      }
    },

    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: isEmpty(filters) ? (this.initial?.filters || {}) : filters,
        label: this.label,
      }
    },

    parametersFrom({ ...rest }) {
      const search = decodeURIComponent(window.location.search)
      const data = queryStringParser(search)
      const query = parsePaginationUrl()
      const pagination = {
        limit: query.limit || this.initial.limit,
        offset: query.offset || 0,
      }

      return {
        ...rest,
        filters: { ...data, ...this.initial?.filters },
        label: this.label,
        ...pagination,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
