import {
  baseResource,
  createResource,
} from '@resource/resource'
import { friendlyUrlGenerator } from '@utils/friendly'
import { prefixLanguage } from '@utils/urls'
import { prefixAPI } from '@resource/api'

const SEARCH_SIMPLE_URL = prefixAPI('search/{?query,limit}')

const SEARCH_LIST_URL_PREFIX = prefixLanguage('/search')
const SEARCH_LIST_URL_POSTFIX = 'ajax/filters'

const orderAdditional = {
  path: [
    'prefix',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
}

export const searchSimpleResource = createResource(SEARCH_SIMPLE_URL)

export const searchListResource = friendlyUrlGenerator([
  SEARCH_LIST_URL_PREFIX,
  SEARCH_LIST_URL_POSTFIX,
], baseResource, orderAdditional)
