<template lang='pug'>
  include /mixins
  +b.ds-panel.--space_xl(v-if="filters.length")
    +e.element.--offset_bottom
      +b.i-preloader.--variant_1(:class="{'is-active': loading}")
        +e.item.--size_md
      filter-ui(
        :disabled="loading"
        :value="prepared"
        :filters="filters"
        :pagination='pagination'
        :needSearch="needSearch"
        @input="handleInputFilter"
      )
        +b.g-cell
          +b.BUTTON.control-button(
            @click.prevent="handleInputFilter({})"
          )
            +e.icon
              +b.I.icon-clear-filter
</template>

<script>

import ControllerMixin from './mixins/ControllerMixin'

export default {
  mixins: [ControllerMixin],
}

</script>
