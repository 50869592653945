const CartPreview = () => import('./CartPreview')
const Quantity = () => import('./Quantity')
const Order = () => import('./Order')
const Gallery = () => import('./Gallery')

export default function install(Vue) {
  Vue.component('product-cart-preview', CartPreview)
  Vue.component('product-quantity', Quantity)
  Vue.component('product-order', Order)
  Vue.component('product-gallery', Gallery)
}
