<template lang="pug">
  include /mixins
  filters-renderer(
    :widgets-map="$options.widgetsMap"
    :prepared-filters="filters"
    :update-multiple-values="updateMultipleValues"
    :update-value="updateValue"
    :value="preparedValues"
  )
</template>

<script>
import UiMixin from './mixins/UiMixin'
import FiltersRenderer from './FiltersRenderer'
import PrepareValuesMixin from './mixins/PrepareValuesMixin'

export default {
  components: {
    FiltersRenderer,
  },

  props: [
    'disabled',
    'value',
    'filters',
    'available',
    'pagination',
    'additionalResource',
    'label',
  ],

  mixins: [
    UiMixin,
    PrepareValuesMixin,
  ],
}
</script>
