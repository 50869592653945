import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const Provider = () => import('./views/Provider')
const ContactList = () => import('./views/contact/List')
const CallbackList = () => import('./views/callback/List')
const CooperationList = () => import('./views/cooperation/List')

const ROUTE_NAME = 'profile:request:'

const p = name => ROUTE_NAME + name

const CONTACT_LIST = p('contact:list')
const CALLBACK_LIST = p('callback:list')
const COOPERATION_LIST = p('cooperation:list')
const PROVIDER = p('provider')

export function createRoutes(options) {
  return [
    route('list/', Provider, PROVIDER, {
      props: true,
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR, TYPES.MANAGER], options),
      },
      children: [
        route('contact/', ContactList, CONTACT_LIST, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.ADMINISTRATOR], options),
          },
        }),
        route('callback/', CallbackList, CALLBACK_LIST, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.ADMINISTRATOR], options),
          },
        }),
        route('cooperation/', CooperationList, COOPERATION_LIST, {
          meta: {
            accessConfig: accessPermission([TYPES.MANAGER, TYPES.ADMINISTRATOR], options),
          },
        }),
      ],
    }),
  ]
}
