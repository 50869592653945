import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const List = () => import('./views/List')

const ROUTE_NAME = 'profile:payoff:list'

export function createRoutes(options) {
  return [
    route('list/', List, ROUTE_NAME, {
      meta: {
        accessConfig: accessPermission([TYPES.MANAGER, TYPES.ADMINISTRATOR], options),
      },
    }),
  ]
}
