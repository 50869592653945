import { mergeContext } from '@aspectus/vue-utils';
import { tableField } from './utils';
import Table from './Table';

const FIELD_NAME = 'element-selection';

export default {
  name: 'vue-selection-table',

  functional: true,
  props: {
    fields: {},
    aggregateFields: {},
    selection: {},
    items: {},
    aggregate: {},
    tableComponent: {
      default: () => Table,
    },
  },

  render(h, context) {
    const {
      fields, selection, items, aggregate, aggregateFields, tableComponent,
    } = context.props;
    const scopedSlots = selection ? {
      [`head-cell-${FIELD_NAME}`]: function headSelection() {
        return h('d-control-checkbox', {
          props: {
            checked: selection.isAll || selection.isTotal,
            indeterminate: !selection.isAll && selection.isSelected,
          },
          on: {
            click: () => selection.changeSelected(
              (selection.isAll || selection.isTotal) ? [] : items
            ),
          },
        });
      },
      [`cell-${FIELD_NAME}`]: function cellSelection({ item }) {
        return h('d-control-checkbox', {
          props: {
            checked: selection.checkSelected(item),
          },
          on: {
            click: () => selection.changeSelected(item),
          },
        });
      },
    } : {};

    return h(tableComponent, mergeContext(context.data, {
      attrs: {
        fields: selection ? [tableField(FIELD_NAME)].concat(fields) : fields,
        items,
        aggregateFields,
        aggregate,
      },
      scopedSlots: Object.assign(scopedSlots, context.scopedSlots),
    }), context.children);
  },
};
