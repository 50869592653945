<template lang='pug'>
  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
  )
    resource-loader-controller(
      :resource="additionalResource"
      v-slot="additionalProps"
    )
      component(
        :is="filterName"
        :value="filters"
        :filters="filtersMap"
        @input="changeFilters"
        :allProps="allProps",
        :pagination='pagination'
        :label='label',
        :additionalProps="additionalProps"
        :additionalResource="additionalResource"
        :totalItems="totalItems"
        :filterKey="filterKey"
      )
</template>

<script>
import DefaultFilter from '@components/Filters'
import CabinetFilter from '@components/Filters/Cabinet'

export default {
  components: {
    CabinetFilter,
    DefaultFilter,
  },

  props: [
    'filters',
    'filtersMap',
    'pagination',
    'attributesResource',
    'additionalResource',
    'label',
    'filterName',
    'needSearch',
    'totalItems',
    'filterKey',
    'initial',
  ],

  methods: {
    changeFilters(value) {
      const params = Object.assign(value, { label: this.label })

      if (this.initial?.filters) {
        Object.keys(this.initial.filters).forEach(k => {
          if (params.filters && !params.filters[k]) {
            params.filters[k] = this.initial.filters[k]
          }
        })
      }

      this.$emit('update:filters', params)
    },
  },
}

</script>
