<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--align_end.--space_xl-mac
    +b.g-cell.g-cols.--12-xs.--6-sm.--3-mac(
      v-for="filter in preparedFilters"
      :class="{'g-cols--4-md g-cols--3-lg g-cols--6-xl g-cols--3-hd': filter.type !== 'input' && filter.type !== 'datefield'}"
    )
      tag(
        v-if="filter.type === 'autocomplete'"
        :tag="$options.widgetsMap['autocomplete']"
        :value="preparedValues[filter.name]"
        :filter="{ url: filter.autocompleteSettings.url, placeholder: filter.title, trackBy: filter.autocompleteSettings.valuePath, name: filter.name, field: filter }"
        @input="updateAutocompleteValue(filter, $event)"
      )
      tag(
        v-else
        :tag="$options.widgetsMap[filter.type]"
        :available="available"
        :parameters="parameters"
        :value="preparedValues[filter.name]"
        :filter-name="filter.name"
        :filter="filter"
        @input="'datefield' === filter.type ? updateMultipleValues(filter.name, $event) : updateValue(filter.name, $event)"
      )
    slot
</template>

<script>
import UiMixin from '../mixins/UiMixin'
import PrepareValuesMixin from '../mixins/PrepareValuesMixin'

export default {
  props: [
    'value',
    'filters',
    'available',
    'pagination',
  ],

  mixins: [
    UiMixin,
    PrepareValuesMixin,
  ],

  computed: {
    preparedFilters() {
      return this.filters.map(filter => {
        if ('tree-select' === filter.type) {
          filter.items = filter.items.reduce((acc, item) => {
            const nItem = {
              ...this.getFilter(item),
              level: 1,
            }
            acc.push(nItem)

            item.children = item.children.map(child => {
              const nChild = {
                ...this.getFilter(child),
                level: 2,
              }

              acc.push(nChild)

              child.children = child.children.map(nestedChild => {
                const nNestedChild = {
                  ...this.getFilter(nestedChild),
                  level: 3,
                }

                acc.push(nNestedChild)

                return nestedChild
              })

              return child
            })

            return acc
          }, [])
        }

        return filter
      })
    },
  },

  methods: {
    getFilter(item) {
      const { title, slug } = item

      return { label: title, slug }
    },
  },
}
</script>
