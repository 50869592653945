import BaseView from '@components/List/View'
import CabinetView from '@components/List/ViewCabinet'
import { withDefaultProps } from '@aspectus/vue-utils'
import {
  productsListResource,
  salesListResource,
  offersListResource,
} from '@services/catalog.service'
import { searchListResource } from '@services/search.service'
import { cabinetFilterResource } from '@services/cabinet.service'
import RouterParametersController from './RouterParametersController'

const composer = (
  listUrl,
  filtersUrl,
  controllerName = 'Controller',
  filterName = 'DefaultFilter'
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
})(BaseView)

const composerCabinet = (
  listUrl,
  filtersUrl,
  controllerName = 'ControllerCabinet',
  filterName = 'CabinetFilter'
) => withDefaultProps({
  listResource: () => listUrl,
  filtersResource: () => filtersUrl,
  controllerName: () => controllerName,
  filterName: () => filterName,
})(CabinetView)

const CatalogView = composer(
  productsListResource,
  null,
  'Controller',
  'DefaultFilter'
)

const CatalogSalesView = composer(
  salesListResource,
  null,
  'Controller',
  'DefaultFilter'
)

const CatalogOffersView = composer(
  offersListResource,
  null,
  'Controller',
  'DefaultFilter'
)

const CatalogSearchView = composer(
  searchListResource,
  null,
  'ControllerSimple',
  'DefaultFilter'
)

const CabinetListView = composerCabinet(
  null,
  cabinetFilterResource,
  'ControllerCabinet',
  'CabinetFilter'
)

export default function plugin(Vue) {
  Vue.component('base-catalog-filters', CatalogView)
  Vue.component('search-catalog-filters', CatalogSearchView)
  Vue.component('offers-catalog-filters', CatalogOffersView)
  Vue.component('sales-catalog-filters', CatalogSalesView)
  Vue.component('cabinet-list-view', CabinetListView)
  Vue.component('router-parameters-controller', RouterParametersController)
}
