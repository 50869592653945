import { route } from '@md/routing'
import { accessPermission } from '@md/users/permissions'
import { TYPES } from '@md/users/constances'

const Dashboard = () => import('./views/Dashboard')
const SalesList = () => import('./views/SalesList')
const ResidueList = () => import('./views/ResidueList')

const ROUTE_NAME = 'profile:analytics:'

const p = name => ROUTE_NAME + name

const DASHBOARD = p('dashboard')
const SALES_LIST = p('sales')
const RESIDUE_LIST = p('residue')

export function createRoutes(options) {
  return [
    route('dashboard/', Dashboard, DASHBOARD, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR], options),
      },
    }),
    route('sales/', SalesList, SALES_LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR], options),
      },
    }),
    route('residue/', ResidueList, RESIDUE_LIST, {
      meta: {
        accessConfig: accessPermission([TYPES.ADMINISTRATOR], options),
      },
    }),
  ]
}
