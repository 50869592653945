<template lang="pug">
  include /mixins
  +b.g-row.--appearance_spaced.--justify_between.--align_center
    +b.g-cell.g-cols.--6-xs.--4-sm.--3-md.--narrow-lg(v-for="link in links")
      +b.g-row.--appearance_nowrap.--align_center.--row-reverse-till-xl.--justify_end
        +b.g-cell
          +b.A.ds-link.--size_xs.--size_sm-xl.--color_black.--underline(
            :href="$router.resolve({ name: link.to, query: link.query }).href"
          ) {{ link.title }}
        +b.g-cell(v-if="link.count")
          +b.i-count.--fs_xs
            +e.SPAN.content {{ link.count }}
</template>

<script>
export default {
  inject: ['generalCounters'],

  data() {
    return {
      links: [
        {
          title: this._('Новые заказы, розн'),
          to: 'profile:order:list',
          query: { status: 'NEW', role: 'A1' },
          key: 'newCustomerOrders',
          count: 0,
        },
        {
          title: this._('Новые заказы, дроп'),
          to: 'profile:order:list',
          query: { status: 'NEW', role: 'A2' },
          key: 'newShipperOrders',
          count: 0,
        },
        {
          title: this._('Новые выплаты'),
          to: 'profile:payoff:list',
          query: { status: 'NEW' },
          key: 'newPayoffs',
          count: 0,
        },
        {
          title: this._('Заказы к отгрузке'),
          to: 'profile:order:list',
          // query: { status: 'NEW', delivery_status: ['WC', 'WP'] },
          query: { status: 'IN_WORK' },
          key: 'readyToShippingOrders',
          count: 0,
        },
      ],
    }
  },

  watch: {
    generalCounters: {
      immediate: true,
      deep: true,
      handler(nval) {
        this.setCounters(nval)
      },
    },
  },

  methods: {
    setCounters(data) {
      this.links = this.links.map(el => {
        const count = data.value[el.key]

        if (count) {
          el.count = count
        }

        return el
      })
    },
  },
}
</script>
