import { createStore as authCreateStore } from '@md/auth'
import { profileRetrieveResource } from '@services/cabinet.service'

export function createStore(parameters) {
  return {
    namespaced: true,

    modules: {
      auth: authCreateStore({
        receiveResource: profileRetrieveResource,
        ...parameters,
      }),
    },
  }
}
