<template lang="pug">
  include /mixins
  +b.ds-panel.--space_sm
    +e.element.--offset_vertical
      +b.filter-scroller
        +b.ds-panel.--space_xs(v-for="choice in filter.items")
          +e.element.--offset_vertical
            +b.A.ds-link.--size_sm.--color_black.--inline(
              :href="choice.url"
            ) {{ choice.label }}
</template>
<script>
export default {
  props: ['filter'],
}
</script>
