import Table from './Table';
import TableBody from './TableBody';
import TableItem from './TableItem';
import SelectionTable from './SelectionTable';
import ExpandableTable from './ExpandableTable';

function install(Vue) {
  Vue.component(Table.name, Table);
  Vue.component(TableBody.name, TableBody);
  Vue.component(TableItem.name, TableItem);
  Vue.component(SelectionTable.name, SelectionTable);
  Vue.component(ExpandableTable.name, ExpandableTable);
}

export default { install };
export * from './utils';
