import './public-path'

import Vue from 'vue'
import I18n from 'vue-i18n'
import Router from 'vue-router'
import { Store } from 'vuex'

import { createApp } from '@/app'
import { install } from '@/install'
import { install as installFilters } from '@utils/filters'
import { createCustomRules } from './js/validation/rules'
import appComponents from './js/components'
import './js/validation'
import './js/directives'
import './styles/index.sass'

Vue.config.productionTip = false

Vue.use(install)
Vue.use(appComponents)
Vue.use(installFilters)

const { app } = createApp({
  Vue,
  I18n,
  Router,
  Store,
})

app.$mount('#app')

createCustomRules(app)
