<template lang='pug'>
  router-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :get-data="getData"
    :need-update-url-on-mounted="true"
    v-slot="{ parameters, changeParameters }"
  )
    paginated-resource-controller(
      :resource="listResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      v-slot="paginatedProps"
      @result="$emit('result', $event)"
    )
      component(
        ref="controller"
        :is="controllerName"
        :attributesResource='filtersResource'
        :additionalResource='additionalResource'
        :filterKey="filterKey"
        v-bind="paginatedProps",
        :filtersMap="filtersMap"
        :totalItems="paginatedProps.pagination ? paginatedProps.pagination.total : 0"
        :label='label',
        :filterName="filterName"
        :needSearch="needSearch"
        :needAddBtns="needAddBtns"
      )
        template(#heading)
          slot(name="heading")
        template(#content)
          slot(
            name="content"
            :items="paginatedProps.result ? paginatedProps.result.items : []"
            :total="paginatedProps.result ? paginatedProps.result.pagination.total : 0"
          )
</template>

<script>
import Controller from '@components/List/Controller'
import ControllerCabinet from '@components/List/ControllerCabinet'
import ControllerSimple from '@components/List/ControllerSimple'
import { friendlyUrlParser } from '@components/Filters/transformers'

export default {
  components: {
    Controller,
    ControllerCabinet,
    ControllerSimple,
  },

  props: {
    initial: {},
    baseUrl: {},
    listResource: {},
    filtersResource: {},
    additionalResource: {},
    label: {},
    needSearch: {},
    controllerName: {},
    filterName: {},
    needAddBtns: {},
    filterKey: {},
    filtersMap: {},
  },

  methods: {
    getData() {
      if (this.$refs.controller && this.$refs.controller.getData) {
        this.$refs.controller.getData()
      }
    },

    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || {},
        label: this.label,
      }
    },

    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser(this.baseUrl)

      return {
        ...rest,
        filters: data,
        label: this.label,
      }
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search)
      }
    },
  },
}

</script>
